<template>
  <div class="order-list">
    <div v-if="lists.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        :offset="10"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="(item, index) in lists" :key="index" class="item">
          <div class="item-top flex-x-between flex-xy-center">
            <div class="top-left flex-x-start flex-xy-center">
              <img src="~assets/images/common/line.png" alt="" />
              <div>设备:{{ item.sn }}</div>
            </div>
            <div
              class="top-right"
              :class="[
                item.status === '2' ? 'fail' : '',
                item.status === '1' ? 'arrival' : '',
              ]"
            >
              {{ item.orderStatus }}
            </div>
          </div>
          <div class="item-center">
            <div class="flex-x-between">
              <span>订单时间:</span>
              <p>{{ item.createTime }}</p>
            </div>
            <div class="flex-x-between">
              <span>订单号:</span>
              <p>{{ item.payOrderNo }}</p>
            </div>
            <div v-if="item.logisticsCompany" class="flex-x-between">
              <span>物流公司:</span>
              <p>{{ item.logisticsCompany }}</p>
            </div>
            <div v-if="item.logisticsOrderNo" class="flex-x-between">
              <span>运单号:</span>
              <p>{{ item.logisticsOrderNo }}</p>
            </div>
          </div>
          <div class="item-footer" v-if="item.status === '1'">
            <div class="flex-x-between">
              <span>提现金额:</span>
              <p>{{ item.money }}</p>
            </div>
          </div>
          <div
            class="item-footer"
            v-if="item.status === '3' && item.remark != ''"
          >
            <div class="flex-xy-center">
              <div class="remark">
                {{ item.remark }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-empty description="暂无数据" v-else />
  </div>
</template>

<script>
import { withdrawList } from "api/common";
export default {
  name: "Order",
  data() {
    return {
      lists: [],
      formData: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0, //总共的数据条数
      loading: false,
      finished: false,
    };
  },
  filters: {
    status(val) {
      if (val === "1") {
        return "已到账";
      } else if (val === "2") {
        return "已寄出";
      } else if (val === "3") {
        return "已签收";
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      withdrawList(this.formData).then((res) => {
        if (res.code === 200) {
          let rows = res.data.list; //请求返回当页的列表
          this.loading = false;
          this.total = res.data.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }

          // 将新数据与老数据进行合并
          this.lists = this.lists.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.lists.length >= this.total) {
            this.finished = true;
          }
        } else {
          this.$toast.fail(res.msg);
        }
        console.log(res);
      });
    },
    onLoad() {
      this.formData.pageNum++;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.order-list {
  font-size: 14px;
  margin: 15px;
  .item {
    padding: 18px 15px 15px 0;
    background: #fff;
    margin-top: 15px;
    .item-top {
      .top-left {
        img {
          height: 14px;
          margin-right: 10px;
        }
      }
      .top-right {
        font-size: 13px;
      }
      .fail {
        color: rgba(253, 110, 5, 1);
      }
      .arrival {
        color: rgba(51, 51, 51, 0.6);
      }
    }
    .item-center {
      margin: 15px 0 0 15px;
      padding: 7px 0;
      border-top: 1px solid rgba(51, 51, 51, 0.1);
      color: rgba(30, 36, 49, 0.7);
      div {
        margin: 8px 0;
      }
    }
    .item-footer {
      margin: 0 0 0 15px;
      padding-top: 15px;
      border-top: 1px solid rgba(51, 51, 51, 0.1);
      span {
        color: rgba(30, 36, 49, 0.7);
        p {
          color: #1e2431;
        }
      }
      .remark {
        width: 315px;
        background: rgba(253, 110, 5, 0.05);
        border-radius: 2px;
        text-align: center;
        color: rgba(30, 36, 49, 0.7);
        font-size: 13px;
        padding: 4px 0;
      }
    }
  }
}
</style>
